import React, { Fragment, forwardRef } from 'react';
import * as styles from './index.styles';
import { ButtonHeight, ButtonDesign, ButtonType, ButtonDisplay } from './index.type';
import { MouseEventHandler } from 'react';
import Link from 'next/link';
import { FontSizeName } from '~/styles/font';
import Image from 'next/image';

export type Props = {
  fontSize: FontSizeName;
  mobileFontSize?: FontSizeName;
  buttonHeight: ButtonHeight;
  mobileButtonHeight?: ButtonHeight;
  design?: ButtonDesign;
  children: React.ReactNode;
  display?: ButtonDisplay;
  type?: ButtonType;
  iconSrc?: string;
  iconAlt?: string;
  onClick?: MouseEventHandler;
  tabIndex?: number;
  disable?: boolean;
  round?: boolean;
  testId?: string;
  loading?: boolean;
  id?: string;
  nextLink?: string;
  href?: string;
  openInNewTab?: boolean;
  minWidth?: number;
  bold?: boolean;
};

export const MESSAGE = {
  LOADING: 'ロード中',
} as const;

export const Button = forwardRef<HTMLButtonElement | HTMLAnchorElement, Props>(
  (props: Props, ref) => {
    const content = (
      <Fragment>
        {props.loading ? (
          <Image
            src={'/assets/animation/loading-spinner.svg'}
            alt={MESSAGE.LOADING}
            css={styles.loadingIcon}
            width={64}
            height={64}
          />
        ) : (
          <span css={styles.content.container}>
            {props.iconSrc && (
              <div
                css={styles.content.iconContainer({
                  height: props.buttonHeight,
                  mobileHeight: props.mobileButtonHeight,
                  design: props.design || 'default',
                })}
              >
                <Image
                  src={props.iconSrc}
                  css={styles.content.icon}
                  alt={props.iconAlt || ''}
                  width={35}
                  height={35}
                />
              </div>
            )}
            <span
              css={styles.content.text({
                height: props.buttonHeight,
                mobileHeight: props.mobileButtonHeight,
                design: props.design || 'default',
              })}
            >
              {props.children}
            </span>
          </span>
        )}
      </Fragment>
    );

    const commonProps = {
      'data-testid': props.testId,
      tabIndex: props.tabIndex,
      id: props.id,
      css: [
        styles.design[props.design || 'default'],
        styles.base(props.buttonHeight, props.fontSize, {
          minWidth: props.minWidth,
          mobileButtonHeight: props.mobileButtonHeight,
          mobileFontSize: props.mobileFontSize,
          bold: props.bold,
        }),
        props.disable ? styles.disable : null,
        styles.displayType(props.display || 'inline'),
        props.round ? styles.round : null,
        props.loading ? styles.loading : null,
        props.href ? styles.anchor(props.buttonHeight) : null,
        styles.padding(props.fontSize),
      ],
    };

    const working = !(props.disable || props.loading);

    if (!props.nextLink && props.href) {
      return (
        <a
          ref={ref as React.RefObject<HTMLAnchorElement>}
          {...commonProps}
          href={props.href}
          onClick={working ? props.onClick : () => {}}
          {...(props.openInNewTab
            ? {
                target: '_blank',
                rel: 'noopener noreferrer',
              }
            : {})}
        >
          {content}
        </a>
      );
    }

    const button = (
      <button
        {...commonProps}
        ref={ref as React.RefObject<HTMLButtonElement>}
        disabled={props.disable}
        type={props.type || 'button'}
        onClick={working ? props.onClick : () => {}}
      >
        {content}
      </button>
    );

    return working && props.nextLink ? (
      <Link href={props.nextLink} css={styles.decorationNone}>
        {button}
      </Link>
    ) : (
      button
    );
  }
);
